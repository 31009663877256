import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { routes } from "./data";
import "./Sidebar.css";
import { BarsOutlined } from "@ant-design/icons";
import edpedialogo from "../../assets/images/logo.svg";
import Cookies from "js-cookie";
import { todayTimeTableService } from "../../Services/TodayTimeTableService";
import { basicFeatures } from "../../Constants/basicFeatures";

const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [components, setComponents] = useState([]);
  const entity = Cookies.get("entity");

  useEffect(() => {
    getComponentList();
  }, []);

  const getComponentList = () => {
    const params = { module: entity };
    todayTimeTableService
      .sidebarComponents(params)
      .then((res) => {
        setComponents([...res.data, ...basicFeatures]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();

  const itemss = routes?.filter((item) => {
    const matchingFeatureMainItem = components?.find(
      (feature) => feature.routePath === "/" + item.key
    );
  

    const hasGrantAccessInChildren = item.children?.some((child) => {
      const matchingFeatureChild = components?.features?.find(
        (feature) => feature.routePath === "/" + child.key
      );
      return matchingFeatureChild && matchingFeatureChild.grantAccess;
    });

    return (
      (matchingFeatureMainItem && matchingFeatureMainItem.grantAccess) ||
      hasGrantAccessInChildren
    );
  });
  
  itemss.push(routes[0], routes[1]);

  function openPage(id) {
    navigate(`${id}`);
  }

  function handleClick(index) {
    setSelected(index);
  }

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-80%",
    },
  };
  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("/time-table")) {
      setSelected("time-table");
    } else if (pathname.includes("/attendance")) {
      setSelected("attendance");
    } else if (pathname.includes("/subject")) {
      setSelected("subject");
    } else if (pathname.includes("/syllabus")) {
      setSelected("syllabus");
    } else if (pathname.includes("/library")) {
      setSelected("library");
    } else if (pathname.includes("/exams")) {
      setSelected("exams");
    } else if (pathname.includes("/results")) {
      setSelected("results");
    } else if (pathname.includes("/assignments")) {
      setSelected("assignments");
    } else if (pathname.includes("/study")) {
      setSelected("study");
    } else if (pathname.includes("/holidays")) {
      setSelected("holidays");
    } else if (pathname.includes("/announcement")) {
      setSelected("announcement");
    } else if (pathname.includes("/cultural")) {
      setSelected("cultural");
    } else if (pathname.includes("/library")) {
      setSelected("library");
    } else if (pathname.includes("/birthday")) {
      setSelected("birthday");
    } else if (pathname.includes("/transport")) {
      setSelected("transport");
    } else if (pathname.includes("/edpediaStore")) {
      setSelected("edpediaStore");
    } else if (pathname.includes("/Assignments")) {
      setSelected("Assignments");
    } else if (pathname.includes("/fee-detail")) {
      setSelected("fee-detail");
    } else if (pathname.includes("/daily-updates")) {
      setSelected("/daily-updates")
    }
     else {
      setSelected("dashboard");
    }
  }, [location]);
  return (
    <React.Fragment>
      <div
        className="bars"
        style={toggle ? { left: "70%" } : { left: "5%" }}
        onClick={() => setToggle(!toggle)}
      >
        <BarsOutlined />
        {/* <FiMenu className="sidebar-icon" /> */}
      </div>
      <motion.div
        className="sidebar"
        id="culture"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${toggle}` : ""}
      >
        {/* <h5>ED-Student</h5> */}
        <img className="Logo" src={edpedialogo} alt="School Img" />
        <div className="sidebar-grid grid-sty  ">
          {itemss.map((item, index) => {
            return (
              <div
                className="sidebar-item"
                key={item.key}
                onClick={() => {
                  openPage(item.path);
                  handleClick(item.key);
                }}
              >
                <div
                  className={
                    selected === item.key
                      ? "sidebar-img-active div-sty flexBox-sty sidecol"
                      : "sidebar-img div-sty flexBox-sty sidecol"
                  }
                >
                  <span
                    className={
                      selected === item.key ? "item-icon-active " : "item-icon"
                    }
                  >
                    {item.img}
                  </span>
                  <p>{item.link}</p>
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default Sidebar;
