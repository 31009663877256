import { Table } from 'antd'
import React, { useState } from 'react'
import { FilePdfFilled } from '@ant-design/icons';
import { DIARY_NOTE } from '../../Constants/StringConstants';

const DiaryNote = ({
  diaryNoteData,
  handleFileView,
}) => {

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Notes",
      dataIndex: "diaryNoteUrl",
      render: (diaryNoteUrl) => diaryNoteUrl?.length > 0 ? (<FilePdfFilled onClick={() => handleFileView(diaryNoteUrl, DIARY_NOTE)} />) : ("-"),
    },
  ];

  return (
    <Table
      dataSource={diaryNoteData}
      columns={columns}
      pagination={{ pageSize: 20 }}
    />
  )
}

export default DiaryNote