export const DIARY_NOTE = "Diary Note";
export const DAILY_NOTES = "Daily Notes";

export const FEE_SERVICE = "FEE_SERVICE";

export const INSTALLMENTS = "Installments";
export const SPECIAL_FEE = "Special Fee";
export const ADVANCED_FEE = "Advanced Fee";
export const FEE_TYPES = [INSTALLMENTS, SPECIAL_FEE, ADVANCED_FEE];

export const PAID = "PAID";
export const UNPAID = "UNPAID";
export const PARTIAL = "PARTIAL";