import { GET_CLASS_SUBJECTS, GET_DAILY_NOTES, GET_DIARY_NOTE } from "../Constants/constants";
import { GetAPIRequest } from "./Api";

const getDailyNotes = (classId) => {
  return GetAPIRequest({
    url: GET_DAILY_NOTES.replace("{classId}", classId),
  });
};
const getDiaryNotes = (classId) => {
  return GetAPIRequest({
    url: GET_DIARY_NOTE.replace("{classId}", classId),
  });
};
const getClassRelatedSubjectList = (classId) => {
  return GetAPIRequest({
    url: GET_CLASS_SUBJECTS.replace("{classId}", classId),
  });
};


export const StudyPlanService = {
  getDailyNotes,
  getDiaryNotes,
  getClassRelatedSubjectList,
};
