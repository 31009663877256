import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  Sidebar,
  Profile,
  Holiday,
  Schoolname,
  EdpediaStore,
  Footer,
  Dashboard,
} from "./components";
import { useSelector } from "react-redux";
import { selectMenu } from "./features/toggleSlice";
// import "./App.css";
import {
  Home,
  Birthdays,
  Transports,
  Timetables,
  Assignments,
  Results,
  Cultures,
  FeeDetails,
  Subjects,
  Attendances,
  Holidays,
  Exams,
  Syllabus,
  Studies,
  Library,
  MyProfile,
} from "./pages";
import Librarys from "./pages/Library/Librarys";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import { Spin, notification } from "antd";
import Cookies from "js-cookie";
import { checkAuth } from "./Utils/cookie-helper";
import NotFoundPage from "./components/NoDataFound/NotFound";
import EdpediaUserStore from "./pages/EdpediaStore/EdpediaStore";
import Address from "../src/components/EdpediaStore/Address/UserAddress";
import Orders from "../src/components/EdpediaStore/Orders/Orders";
import Wishlist from "./components/EdpediaStore/Wishlist/Wishlist";
import UploadDocument from "./components/UploadDocuments/UploadDocument";
import ProfileDetails from "./components/Profile/ProfileDetails/ProfileDetails";
import { todayTimeTableService } from "./Services/TodayTimeTableService";
import DailyUpdate from "./components/DailyUpdate/DailyUpdate";
import StudyPlan from "./components/StudyPlan/StudyPlan";
import { basicFeatures } from "./Constants/basicFeatures";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const brachCode = Cookies.get("branchCode");
  const show = useSelector(selectMenu);
  const [showNotification, setShowNotification] = useState(false);
  const [components, setComponents] = useState([]);
  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const entity = Cookies.get("entity");

  const getToken = () => {
    const token = Cookies.get("accessToken");
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
      window.location.replace("https://dev.d285gbdoz6er7k.amplifyapp.com/");
    }
  };

  useEffect(() => {
    let token = Cookies.get("accessToken");
    if (token === null || token === "" || (token === undefined && !isAuth)) {
      checkAuth();
    } else if (token) {
      checkAuth();
      setIsAuth(true);
    }
  }, [isAuth]);

  const studentInfo = useSelector(
    (state) => state.studentInfo
  );

  console.log('studentInfo', studentInfo)

  const studentId = studentInfo?.studentInfo?.studentId;

  const classId = studentInfo?.studentInfo?.classId

  console.log('studentId', studentId)

  useEffect(() => {
    const socket = new SockJS("https://api.edpedia.co/v1.0/student/ws");
    const stompClient = Stomp.over(socket);

    const connectCallback = (frame) => {
      stompClient.subscribe(
        `/topic/${brachCode}/exam/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 5,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/results/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 5,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/assignment/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 5,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/attendance/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 5,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/library/student/${studentId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 5,
            placement: "topRight",
          });
        }
      );
    };

    stompClient.connect({}, connectCallback);

    return () => {
      stompClient.disconnect();
    };
  }, []);

  const getComponentList = () => {
    const params = { module: entity };
    todayTimeTableService
      .sidebarComponents(params)
      .then((res) => {
        setComponents([...res.data, ...basicFeatures]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComponentList();
  }, []);

  const routesList = [
    {
      path: "/dashboard",
      component: Home,
    },
    {
      path: "/profile",
      component: MyProfile,
    },
    {
      path: "/fee-detail",
      component: FeeDetails,
    },
    {
      path: "/birthday",
      component: Birthdays,
    },
    {
      path: "/holidays",
      component: Holidays,
    },
    {
      path: "/subject",
      component: Subjects,
    },
    {
      path: "/attendance",
      component: Attendances,
    },
    {
      path: "/study-plan",
      component: StudyPlan,
    },
    {
      path: "/time-table",
      component: Timetables,
    },
    {
      path: "/cultural",
      component: Cultures,
    },
    {
      path: "/profile",
      component: Profile,
    },
    {
      path: "/Assignments",
      component: Assignments,
    },
    {
      path: "/study",
      component: Studies,
    },
    {
      path: "/results",
      component: Results,
    },
    {
      path: "*",
      component: NotFoundPage,
    },
    {
      path: "/syllabus",
      component: Syllabus,
    },
    {
      path: "/exams",
      component: Exams,
    },
    {
      path: "/library",
      component: Librarys,
    },
    {
      path: "/Holidays",
      component: Holiday,
    },
    {
      path: "/results",
      component: Results,
    },
    {
      path: "/edpediaStore",
      component: EdpediaUserStore,
    },
    {
      path: "/transport",
      component: Transports,
    },
    {
      path: "/daily-update",
      component: DailyUpdate
    },

    {
      path: "/address",
      component: Address,
    },
    {
      path: "/orders",
      component: Orders,
    },

    {
      path: "/wishlist",
      component: Wishlist,
    },
    {
      path: "upload_document",
      component: UploadDocument
    }
  ];

  const getAllowedRoutes = routesList?.filter((item) => {
    const matchingFeature = components?.find(
      (feature) => feature.routePath === item.path
    );
    return matchingFeature && matchingFeature.grantAccess === true;
  });

  const student = localStorage.getItem("activeStudent");
  console.log("ssss", student);

  return (
    <div className="App">
      {isAuth && (
        <div className={show ? "appBar-active" : "appBar"}>
          <>
            <Sidebar />
            <Schoolname />
            <Routes>
              <Route
                path="/"
                element={<Navigate to={getAllowedRoutes[0]?.path} />}
              />
              {getAllowedRoutes?.map((route, index) => (
                <Route
                  key={index}
                  exact
                  path={route?.path}
                  element={
                    <Suspense
                      fallback={
                        <Spin
                        // indicator={<Loading />}
                        />
                      }
                    >
                      <route.component StudentId="4" />
                    </Suspense>
                  }
                />
              ))}

                <Route
                  path="/upload_document"
                  element={
                    <Suspense >
                      <UploadDocument studentId={studentId} />
                    </Suspense>
                  }
                /> 
                <Route 
                  path="/daily-update"
                  element={
                    <Suspense>
                      <DailyUpdate classId={classId} studentId={studentId}/>
                    </Suspense>
                  }

                />

              {/* <Route path="*" element={<NotFoundPage />} /> */}
            </Routes>

          </>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
