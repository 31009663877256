import { Radio } from 'antd';
import React, { useEffect, useState } from 'react'
import { DAILY_NOTES, DIARY_NOTE } from '../../Constants/StringConstants';
import Cookies from "js-cookie";
import DiaryNote from './DiaryNote';
import DailyNotes from './DailyNotes';
import { StudyPlanService } from '../../Services/StudyPlanService';
import FloatSelect from '../Common/FloatLable/Select';
import "./studyPlan.scss";
import FileViewMultiple from '../../Utils/FileViewMultiple';

const StudyPlan = () => {
    const [activeTab, setActiveTab] = useState(DIARY_NOTE);
    const [diaryNoteData, setDiaryNoteData] = useState(null);
    const [dailyNotes, setDailyNotes] = useState(null);
    const [subjectData, setSubjectData] = useState([])
    const [selectedSubject, setSelectedSubject] = useState();
    const [fileView, setFileView] = useState();
    const RadioGroup = Radio.Group;
    const student = JSON.parse(Cookies.get("studentInfo"))

    useEffect(() => {
        getClassAssociatedSubjects();
        getDiaryNotesData();
        getDailyNotesData();
    }, [student?.classId]);

    const getClassAssociatedSubjects = () => {
        StudyPlanService.getClassRelatedSubjectList(student?.classId)
            .then((response) => {
                let classSubjects = [];
                response?.data?.forEach(eachClassSubject => (
                    eachClassSubject?.subjects?.forEach(eachSubject => {
                        classSubjects.push({ subjectId: eachSubject?.subjectId, subject: eachSubject?.subjectName });
                    })
                ))
                setSubjectData(classSubjects);
                setSelectedSubject(classSubjects?.[0]?.subjectId)
            })
            .catch((error) => {
            });
    };

    const getDiaryNotesData = () => {
        if (student?.classId) {
            StudyPlanService.getDiaryNotes(student?.classId)
                .then(response => {
                    setDiaryNoteData(response?.data?.reverse());
                })
                .catch(error => { });
        }
    }
    const getDailyNotesData = () => {
        if (student?.classId) {
            StudyPlanService.getDailyNotes(student?.classId)
                .then(response => {
                    setDailyNotes(response?.data?.reverse());
                })
                .catch(error => { });
        }
    }
    const filteredNotes = () => {
        return dailyNotes?.filter(notes => notes?.subjectId === selectedSubject);
    }

    const handleFileView = (url, heading) => {
        setFileView({ view: true, url, heading })
    }

    return (
        <main>
            <div className="flex-between">
                <div className="radio-group-buttons">
                    <RadioGroup
                        onChange={(event) => setActiveTab(event.target.value)}
                        value={activeTab}
                        optionType="button"
                        buttonStyle="solid"
                    >
                        <Radio.Button value={DIARY_NOTE}>
                            {DIARY_NOTE}
                        </Radio.Button>
                        <Radio.Button value={DAILY_NOTES}>
                            {DAILY_NOTES}
                        </Radio.Button>
                    </RadioGroup>
                </div>
                {activeTab === DAILY_NOTES && (
                    <FloatSelect
                        label="Select Subject"
                        value={selectedSubject}
                        style={{ width: "150px" }}
                        onChange={(value) => setSelectedSubject(value)}
                        options={subjectData?.map(subject => ({ label: subject?.subject, value: subject?.subjectId }))}
                    />
                )}
            </div>
            {activeTab === DIARY_NOTE && (
                <DiaryNote
                    diaryNoteData={diaryNoteData}
                    handleFileView={handleFileView}
                />
            )}
            {activeTab === DAILY_NOTES && (
                <DailyNotes
                    dailyNotesData={filteredNotes()}
                    handleFileView={handleFileView}
                />
            )}
            {fileView?.view && (
                <FileViewMultiple
                    view={fileView?.view}
                    setView={setFileView}
                    fileData={fileView?.url}
                    heading={fileView?.heading}
                />
            )}
        </main>
    )
}

export default StudyPlan