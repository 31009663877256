import { GET_FEE_DATA } from "../Constants/constants";
import { FEE_SERVICE } from "../Constants/StringConstants";
import { GetAPIRequest } from "./Api";

const getStudentFeeData = (studentId, params) => {
  return GetAPIRequest({
    url: GET_FEE_DATA.replace("{studentId}", studentId),
    params,
    service: FEE_SERVICE,
  });
};


export const FeeService = {
  getStudentFeeData,
};
