import axios from "axios";
import Cookies from "js-cookie";
import config from "../Utils/Config";
import { FEE_SERVICE } from "../Constants/StringConstants";
const accessToken = Cookies.get("accessToken");

const redirectToLogin = () => {
  window.location.href = `${config.loginUrl}`;
};

const request = async function (options, service=false) {
  const accessToken = Cookies.get("accessToken");
  let client;
  if (!accessToken || accessToken === "undefined" || accessToken === "null") {
    redirectToLogin();
    return;
  } else {
    if (service === false) {
      client = axios.create({
        withCredentials: false,
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      });
    }
    else if (service === FEE_SERVICE) {
      client = axios.create({
        withCredentials: false,
        headers: {
          "BranchCode": Cookies.get("branchCode"),
        },
      });
      
    }

    const onSuccess = function (response) {
      return response?.data;
    };

    const handleStatusCode = function (statusCode) {
      switch (statusCode) {
        case 200:
          console.log("statusCode: 200");
          break;
        case 404:
          console.log("statusCode: 404 - Resource not found");
          break;
        case 500:
          console.log("statusCode: 500 - Internal Server Error");
          break;
        case 403:
          console.log("statusCode: 403 - Forbidden");
          break;
        default:
          console.log("statusCode:", statusCode);
          
      }
    };

    const onError = function (error) {
      if (error?.response) {
        handleStatusCode(error?.response?.status);
      } else {
        console.log("error message:", error?.message);
      }
      return Promise.reject(error?.response || error?.message);
    };

    try {
      const response = await client(options);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  }
};

export const GetAPIRequest = (options) => {
  const { url, service, ...otherOptions } = options;

  if (accessToken) {
    return request({
      url: url,
      withCredentials: false,
      method: "GET",
      ...otherOptions,
    }, service);
  } else {
    redirectToLogin();
  }
};

export const PostAPIRequest = (options) => {
  const { url, service, ...otherOptions } = options;
  if (accessToken) {
    return request({
      url: url,
      withCredentials: false,
      method: "POST",
      data: options.data,
      ...otherOptions,
    }, service);
  } else {
    redirectToLogin();
  }
};

export const PatchAPIRequest = (options) => {
  const { url, service, ...otherOptions } = options;
  if (accessToken) {
    return request({
      url: url,
      withCredentials: false,
      method: "PATCH",
      data: options.data,
      ...otherOptions,
    }, service);
  } else {
    redirectToLogin();
  }
};

export const PutAPIRequest = (options) => {
  const { url, service, ...otherOptions } = options;
  if (accessToken) {
    return request({
      url: url,
      withCredentials: false,
      method: "PUT",
      ...otherOptions,
    }, service);
  } else {
    redirectToLogin();
  }
};

export const DeleteAPIRequest = (options) => {
  const { url, service, ...otherOptions } = options;
  if (accessToken) {
    return request({
      url: url,
      withCredentials: false,
      method: "DELETE",
      ...otherOptions,
    }, service);
  } else {
    redirectToLogin();
  }
};

// file upload

export const downloadFile = async ({url,params, service = false}) => {
  const queryString = new URLSearchParams(params).toString();
  const URL=`${url}?${queryString}`;
  let headers;
  if (service === FEE_SERVICE) {
    headers = {"BranchCode": Cookies.get("branchCode")};
  }
  else {
    headers = {Authorization: `Bearer ${accessToken}`};
  }
  
  try {
    const response = await axios.get(URL, {
      responseType: "arraybuffer",
      headers,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to download file: " + error);
  }
};

export const fileUpload = async (url, formData) => {
  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    });
    return response.data;
  } catch (error) {
    redirectToLogin();
    throw new Error("Failed to upload file: " + error.message);
  }
};

export default request;
