import { notification } from "antd";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import moment from "moment";

export const successNotification = (message) => {
  notification.success({
    message,
    icon: (
      <CheckCircleFilled
        style={{
          color: "green",
        }}
      />
    ),
    duration: 3,
    placement: "bottomRight",
  });
};
export const errorNotification = (message) => {
  notification.error({
    message,
    placement: "bottomRight",
    icon: <DislikeOutlined style={{ color: "red" }} />,
    duration: 3,
  });
};


export function dateFormat(date) {
  return dayjs(date).format("DD-MM-YYYY");
}

export function dateWithTime(date) {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

export const YearMonthDate=(date)=>{
  return moment(date).format("YYYY-MM-DD");
}

export const validateFilesWithExtensionsAndSize = (fileList, extensions, maxSizeMB, errorMessage) => {
  const MAX_SIZE_BYTES = maxSizeMB * 1024 * 1024;
  const validFiles = [];
  const invalidFiles = [];

  fileList.forEach((file) => {
    const isAcceptedType = extensions.some(extension => file.name.endsWith(extension));
    const isUnderMaxSize = file.size <= MAX_SIZE_BYTES;

    console.log('isAcceptedType', isAcceptedType)
    console.log('isUnderMaxSize', isUnderMaxSize)

    if (isAcceptedType && isUnderMaxSize) {
      validFiles.push(file.originFileObj);
    } else {
      invalidFiles.push(file);
    }
  });

  if (invalidFiles.length > 0) {
    notification.error({
      message: errorMessage,
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
  });
  }

  if(validFiles.length > 0){
    return validFiles
  }else{
    return []
  }
};
