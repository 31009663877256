import { Table } from 'antd'
import React from 'react'
import { FilePdfFilled } from '@ant-design/icons';
import { DAILY_NOTES } from '../../Constants/StringConstants';

const DailyNotes = ({
  dailyNotesData,
  handleFileView,
}) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Notes",
      dataIndex: "dailyNotes",
      render: (dailyNotes) => dailyNotes?.length > 0 ? (<FilePdfFilled onClick={() => handleFileView(dailyNotes, DAILY_NOTES)} />) : ("-"),
    },
  ];
  return (
    <Table
          dataSource={dailyNotesData}
          columns={columns}
          pagination={{ pageSize: 20 }}
        />
  )
}

export default DailyNotes