export const BASE_LOGIN_URL = `https://api.edpedia.co/v1.0/ulp`;
export const BASE_URL = `https://api.edpedia.co/v1.0/student/`;
export const STAFF_BASE_URL = "https://api.edpedia.co/v1.0/staff";
export const FEE_MANAGEMENT_BASE_URL = "https://api.edpedia.co/v1.0/fee_service";
// export const FEE_MANAGEMENT_BASE_URL = "http://localhost:8096/v1.0/fee_service";

//export const STAFF_BASE_URL = `http://15.207.40.153:8013/v1.0`;
//export const EDIT_SYLLABUS_BASE_URL = `http://15.207.40.153:8080/v1.0`;
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = API_BASE_URL;
export const INSTITUTE_BASE_URL = `https://api.edpedia.co/v1.0/instAdmin`;
// export const INSTITUTE_BASE_URL = `http://localhost:8081/v1.0/instAdmin/`;

//Get Daily Update
export const TODAY_DAILY_UPDATE = INSTITUTE_BASE_URL + `/api/dailyUpdates/`;
export const GET_DAILY_UPDATE = "/get";

//Get Today Time Table
export const GET_TODAY_TIMETABLE = BASE_URL + `/api/student/`;
export const GET_TODAY_TIMETABLE_END_PONT = `/getTodayTimetable`;

//student image upload
export const UPLOAD_IMAGE = BASE_URL + `api/student/`;
export const UPLOAD_IMAGE_ENDPOINT = `/uploadProfilePic`;

//find student
export const FINDSTUDENT = INSTITUTE_BASE_URL + `/api/student/`;
export const FIND_STUDENT_ENDPOINT = `/find`;
//editStudent

export const EDITSTUDENT = INSTITUTE_BASE_URL + `/api/student/`;
export const EDIT_STUDENT_ENDPOINT = `/edit`;

//syllabus

export const DOWNLOAD_SYLLABUS =
  INSTITUTE_BASE_URL + `/api/syllabus/exportSyllabus?classId=1&subjectId=2`;

//uicomponent
export const SIDEBAR_COMPONENTS =
  INSTITUTE_BASE_URL + `/api/uiComponent/findByModule`;

//parent find api
export const PARENT_FIND = INSTITUTE_BASE_URL + `/api/parent/`;
export const PARENT_FIND_ENDPOINT = `/find`;

//documents upload
export const DOCUMENT_UPLOAD = BASE_URL + `api/student/`
export const DOCUMENT_UPLOAD_ENDPOINT = `/saveOrUpdateDocuments`

export const UPLOAD_CERTIFICATE = BASE_URL + `api/student/`
export const UPLOAD_DOB_CERTIFICATE_ENDPOINT = `/save_dob_certificate`
export const UPLOAD_AADHAAR_CERTIFICATE_ENDPOINT = `/save_aadhaar_copies`
export const UPLOAD_BLOOD_PROOF_ENDPOINT = `/save_blood_group_proof`
export const UPLOAD_TC_ENDPOINT = `/save_tc`

export const GET_UPLOADED_CERFICATE = BASE_URL + `api/student/`
export const GET_UPLOADED_CERFICATE_ENDPOINT = `/findDocuments`

// Subjects
export const GET_CLASS_SUBJECTS = INSTITUTE_BASE_URL + "/api/class/{classId}/getClassSubjects";

// Study Plan
export const GET_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/{classId}/get-daily-notes";
export const GET_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/{classId}/get-diary-note";

// Fee Management
export const GET_FEE_DATA = FEE_MANAGEMENT_BASE_URL + "/api/reports/student/{studentId}/fee-report"