import React, { useState, useEffect } from "react";
import { Button, Modal, Radio, Spin } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { INSTITUTE_BASE_URL } from "../Constants/constants";
import { errorNotification } from "../Constants/utilities";

const FileViewMultiple = ({ view, setView, fileData, heading }) => {
    const [selectedFileIndex, setSelectedFileIndex] = useState(0);
    const [fileContent, setFileContent] = useState(null);
    const accessToken = Cookies.get("accessToken");
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setView(false);
    };

    const handleFileChange = (e) => {
        setSelectedFileIndex(e.target.value);
    };

    useEffect(() => {
        const fetchFileContent = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${INSTITUTE_BASE_URL}/api/storage/viewFile?url=${fileData[selectedFileIndex]}`,

                    {
                        headers: {
                            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
                        },
                    }
                );
                setFileContent(response?.data?.data);
                console.log(response?.data);
            } catch (error) {
                console.error("Error downloading question paper:", error);
                errorNotification("File not found")
            }
            finally {
                setLoading(false);
            }
        };

        fetchFileContent();
    }, [selectedFileIndex, fileData]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = fileContent;
        link.download = "preview-file";
        link.click();
    };


    return (
        <div>
            {loading && (
                <div className="total-page-loading">
                    <Spin size="large" spinning={true} />
                </div>
            )}
            <Modal
                title={`Files View : ${heading}`}
                open={view}
                centered
                onCancel={handleCancel}
                footer={null}
                width={1000}
            >
                <div className="flex-between">
                    <Radio.Group onChange={handleFileChange} value={selectedFileIndex}>
                        {fileData &&
                            fileData?.map((file, index) => (
                                <Radio key={index} value={index}>
                                    {`File ${index + 1}`}
                                </Radio>
                            ))}
                    </Radio.Group>
                    <Button onClick={handleDownload} style={{ marginTop: "1px", marginBottom: "1px" }} className="blue-button">
                        Download File
                    </Button>
                </div>
                <div>
                    <iframe
                        src={fileContent}
                        style={{ width: "100%", height: "500px" }}
                        title="File Preview"
                    />
                </div>
            </Modal>
        </div>
    );
};

export default FileViewMultiple;
