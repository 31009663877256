import React from "react";
import { Schoolname, FeeDetail } from "../../components";

const FeeDetails = () => {
  return (
    <main>
      {/* <Schoolname /> */}
      <FeeDetail />
    </main>
  );
};

export default FeeDetails;
