import React, { useEffect, useState } from "react";
import "./FeeDetail.scss";
import { Radio, Spin, Table, Tooltip } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import { FeeService } from "../../Services/FeeService";
import { ADVANCED_FEE, FEE_TYPES, INSTALLMENTS, PAID, PARTIAL, SPECIAL_FEE, UNPAID } from "../../Constants/StringConstants";
import FloatSelect from "../Common/FloatLable/Select";

const FeeDetail = () => {

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const [loading, setLoading] = useState(false);
  const [feeData, setFeeData] = useState([]);
  const [selectedFeeType, setSelectedFeeType] = useState(INSTALLMENTS);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("2024-25");
  const [selectedRadio, setSelectedRadio] = useState();

  useEffect(() => {
    getFeeData();
  }, [selectedAcademicYear])

  const getFeeData = () => {
    const params = {
      academicYear: selectedAcademicYear,
    }
    setLoading(true);
    FeeService.getStudentFeeData(studentInfo?.studentId, params)
      .then(response => {
        setFeeData(response?.data);
        setSelectedRadio(response?.data?.installmentsFee?.[0]?.component)
      })
      .catch(error => { })
      .finally(() => setLoading(false));
  }

  if (loading) {
    return (
      <div className="total-page-loading">
        <Spin />
      </div>
    );
  }
  let columns = [
    {
      title: "Component",
      dataIndex: "component",
      key: "component",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
    },
    {
      title: "Due Amount",
      dataIndex: "remaining",
      key: "remaining",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  if (selectedFeeType === ADVANCED_FEE) {
    columns = columns.slice(0, 2)
  }

  const filteredData = () => {
    let records;
    switch (selectedFeeType) {
      case (INSTALLMENTS):
        const term = parseInt(selectedRadio?.replace("Term ", ""));
        records = feeData?.feeBreakups?.[term];
        break;
      case (SPECIAL_FEE):
        records = feeData?.studentSpecialFees;
        break;
      case (ADVANCED_FEE):
        records = feeData?.studentConfigurationFees;
        break;
      default:
        return [];
    }
    if (!records || records?.length === 0) return [];
    let total = 0;
    let discount = 0;
    let paid = 0;
    let remaining = 0;
    records?.forEach(record => {
      total += record?.total;
      discount += record?.discount;
      paid += record?.paid;
      remaining += record?.remaining;
    })
    const allPaid = records?.every(record => record?.status === PAID);
    const allUnpaid = records?.every(record => record?.status === UNPAID);
    let status;
    if (records?.length === 0) status = "-";
    else if (allPaid) status = PAID;
    else if (allUnpaid) status = UNPAID
    else status = PARTIAL;
    const totalCalculatedRecord = {
      component: "Total",
      total, discount, paid, remaining, status,
    }
    if (selectedFeeType === ADVANCED_FEE) {
      const discountComponent = {
        component: "Discount",
        total: discount,
      }
      const paidComponent = {
        component: "Paid",
        total: paid,
      }
      const remainingComponent = {
        component: "Due",
        total: remaining,
      }
      return [...records, {}, {}, {}, totalCalculatedRecord, discountComponent, paidComponent, remainingComponent];
    }
    return [...records, {}, {}, {}, totalCalculatedRecord];
  }
  return (
    <>
      <header className="fee-header">
        <h1>Fee Details</h1>
        <div className="flex-row">
          <FloatSelect
            label="Academic Year"
            value={selectedAcademicYear}
            style={{ width: "150px", marginRight:"10px" }}
            onChange={(value) => setSelectedAcademicYear(value)}
            options={["2024-25", "2025-26"]?.map(ac => ({ label: ac, value: ac }))}
          />
          <FloatSelect
            label="Select Fee Type"
            value={selectedFeeType}
            style={{ width: "150px" }}
            onChange={(value) => setSelectedFeeType(value)}
            options={FEE_TYPES?.map(type => ({ label: type, value: type }))}
          />
        </div>
      </header>
      <div className="total_fees">
        <div className="back_container bg1">
          <p>Total Fee</p>
          <h1>{feeData?.overAllFee?.total}</h1>
        </div>
        <div className="back_container bg4">
          <p>Discount</p>
          <h1>{feeData?.overAllFee?.discount}</h1>
        </div>
        <div className="back_container bg2">
          <p>Paid Fee</p>
          <h1>{feeData?.overAllFee?.paid}</h1>
        </div>
        <div className="back_container bg3">
          <p>Due Amount </p>
          <h1>{feeData?.overAllFee?.remaining}</h1>
        </div>
      </div>
      {selectedFeeType === INSTALLMENTS && (
        <div className="flex-row">
          <h1 style={{marginRight:"10px"}}>Select Term</h1>
          <Radio.Group
            // defaultValue="studentPersonalDetails"
            buttonStyle="solid"
            onChange={(e) => setSelectedRadio(e.target.value)}
            value={selectedRadio}
          >
            <div className="tabtop">
              {feeData?.installmentsFee?.map((term) => (
                <Radio.Button
                  key={term.component}
                  value={term.component}
                  style={{ width: "100px", marginRight: "10px" }}
                >
                  {term.component}
                  {term?.status === "PAID" ? (
                    <Tooltip title="Term Fee Paid">
                      <CheckCircleFilled
                        style={{
                          color: "green",
                          position: "absolute",
                          top: 13,
                          right: 7,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <CloseCircleFilled
                      style={{
                        color: "red",
                        position: "absolute",
                        top: 13,
                        right: 7,
                      }}
                    />
                  )}
                </Radio.Button>
              )
              )}
            </div>
          </Radio.Group>
        </div>
      )}
      <Table
        columns={columns}
        dataSource={filteredData()}
        pagination={false}
      />
    </>
  );
};
export default FeeDetail;
